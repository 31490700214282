import styles from './../screen.module.css';
// import { useAtom } from 'jotai';
// import { qubitAtom } from '../screen';
import BlochSphere from '../../../blochSphere/blochSphere';
import 'katex/dist/katex.min.css';
import { InlineMath, BlockMath } from 'react-katex';
import { useDrop } from 'react-dnd';
import { animated } from 'react-spring';
import { useEffect, useState, useCallback } from 'react';
import { Card } from './Card';
import update from 'immutability-helper';
import { FaPlay } from 'react-icons/fa';
import { sqrt } from 'mathjs';

function gateX(animationV_b, animationValue, animationCount, num) {
  const x =
    animationV_b[0] * Math.cos((animationValue * (animationCount * Math.PI)) / num) +
    animationV_b[1] * Math.sin((animationValue * (animationCount * Math.PI)) / num);
  const y =
    -animationV_b[0] * Math.sin((animationValue * (animationCount * Math.PI)) / num) +
    animationV_b[1] * Math.cos((animationValue * (animationCount * Math.PI)) / num);

  const new_theta = Math.acos(y) / Math.PI;
  const new_phi = Math.atan2(x, animationV_b[2]) / Math.PI;
  return [
    [new_theta, new_phi],
    [x, y, animationV_b[2]],
  ];
}

function gateY(animationV_b, animationValue, animationCount, num) {
  const y =
    animationV_b[1] * Math.cos((animationValue * (animationCount * Math.PI)) / num) -
    animationV_b[2] * Math.sin((animationValue * (animationCount * Math.PI)) / num);
  const z =
    animationV_b[1] * Math.sin((animationValue * (animationCount * Math.PI)) / num) +
    animationV_b[2] * Math.cos((animationValue * (animationCount * Math.PI)) / num);
  const new_theta = Math.acos(y) / Math.PI;
  const new_phi = Math.atan2(animationV_b[0], z) / Math.PI;
  return [
    [new_theta, new_phi],
    [animationV_b[0], y, z],
  ];
}

function gateZ(animationV_b, animationValue, animationCount, num) {
  const x =
    animationV_b[0] * Math.cos((animationValue * (animationCount * Math.PI)) / num) +
    animationV_b[2] * Math.sin((animationValue * (animationCount * Math.PI)) / num);
  const z =
    -animationV_b[0] * Math.sin((animationValue * (animationCount * Math.PI)) / num) +
    animationV_b[2] * Math.cos((animationValue * (animationCount * Math.PI)) / num);
  const new_theta = Math.acos(animationV_b[1]) / Math.PI;
  const new_phi = Math.atan2(x, z) / Math.PI;
  return [
    [new_theta, new_phi],
    [x, animationV_b[1], z],
  ];
}

function gateH(animationV_b, animationCount, num) {
  let x, y, z;

  const cos_half_angle = Math.cos((0.5 * (animationCount * Math.PI)) / num);
  const sin_half_angle = Math.sin((0.5 * (animationCount * Math.PI)) / num);

  const qx = 0;
  const qy = sin_half_angle * (1 / Math.sqrt(2));
  const qz = sin_half_angle * (1 / Math.sqrt(2));
  const qw = cos_half_angle;

  const p = {
    x: animationV_b[0],
    y: animationV_b[1],
    z: animationV_b[2],
  };

  x = p.x * (qw * qw + qx * qx - qy * qy - qz * qz) + 2 * p.y * (qx * qy - qw * qz) + 2 * p.z * (qx * qz + qw * qy);
  y = 2 * p.x * (qx * qy + qw * qz) + p.y * (qw * qw - qx * qx + qy * qy - qz * qz) + 2 * p.z * (qy * qz - qw * qx);
  z = 2 * p.x * (qx * qz - qw * qy) + 2 * p.y * (qy * qz + qw * qx) + p.z * (qw * qw - qx * qx - qy * qy + qz * qz);

  const new_theta = Math.acos(y) / Math.PI;
  const new_phi = Math.atan2(x, z) / Math.PI;
  return [
    [new_theta, new_phi],
    [x, y, z],
  ];
}

export default function QubitBox(props) {
  const [isRunning, setIsRunning] = useState(false);
  const [runningCard, setRunningCard] = useState(-1);
  const [measure, setMeasure] = useState(0);

  function animateAsync() {
    // const intervalId = setInterval(animate, 0); //0.3);
    // return () => {
    //   clearInterval(intervalId);
    // };
    return new Promise((resolve) => {
      const num = animationNum;
      let count = 0;
      const intervalId = setInterval(() => {
        count++;
        if (animationCount <= num) {
          if (animationFun == 'X') {
            const result = gateX(animationV_b, animationValue, animationCount, num)[0];
            changeV_ab(props.id, result[0], result[1]);
          } else if (animationFun == 'Y') {
            const result = gateY(animationV_b, animationValue, animationCount, num)[0];
            changeV_ab(props.id, result[0], result[1]);
          } else if (animationFun == 'Z') {
            const result = gateZ(animationV_b, animationValue, animationCount + 1, num)[0];
            changeV_ab(props.id, result[0], result[1]);
          }
          setAnimationCount((prevCount) => prevCount + 1);
        }
        // console.log(animationCount);
        if (animationCount === num + 1) {
          resolve();
        }
        if (count >= 2) clearInterval(intervalId);
      }, 1600);
    });
  }

  const play = async () => {
    if (isRunning === false) {
      setRunningCard(0);
      setIsRunning(true);
      await changeV_ab(props.id, 0, 0);
      let x = 0;
      let y = 1;
      let z = 0;
      let theta = 0;
      for (const item of cards) {
        if (item.gate === 'Measure') {
          const percentage = Math.cos((theta / 2) * Math.PI) ** 2;
          console.log(theta);
          if (Math.random() <= percentage) {
            setMeasure(0);
            changeV_ab(props.id, 0, 0);
            x = 0;
            y = 1;
            z = 0;
            theta = 0;
          } else {
            setMeasure(1);
            changeV_ab(props.id, 1, 0);
            x = 0;
            y = -1;
            z = 0;
            theta = 1;
          }
          setAnimationCount(0);
          setAnimationFun('Z');
          setAnimationV_b([x, y, z]);
          setAnimationValue(0);
          setAnimationNum(10);
          await animateAsync();
        } else {
          setAnimationCount(0);
          setAnimationFun(item.gate);
          setAnimationV_b([x, y, z]);
          setAnimationValue(item.value);
          setAnimationNum(item.num);
          await animateAsync();

          let result;
          if (item.gate === 'X') result = gateX([x, y, z], item.value, 1, 1);
          else if (item.gate === 'Y') result = gateY([x, y, z], item.value, 1, 1);
          else if (item.gate === 'Z') {
            result = gateZ([x, y, z], item.value, 1, 1);
          } else if (item.gate === 'H') {
            result = gateH([x, y, z], 1, 1);
          }
          if (['X', 'Y', 'Z', 'H'].includes(item.gate)) {
            x = result[1][0];
            y = result[1][1];
            z = result[1][2];
            theta = result[0][0];
          }
        }
        setRunningCard((e) => e + 1);
      }
      setIsRunning(false);
      setRunningCard(-1);
    }
  };

  const [cards, setCards] = useState([]);
  const moveCard = useCallback((dragIndex, hoverIndex) => {
    if (isRunning === false) {
      setCards((prevCards) =>
        update(prevCards, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, prevCards[dragIndex]],
          ],
        })
      );
    }
  }, []);

  const renderCard = useCallback((card, index, runningCard) => {
    return (
      <Card
        index={index}
        name={card.name}
        text={card.text}
        moveCard={moveCard}
        runningCard={runningCard}
        deleteCard={(index) => deleteCard(index)}
      />
    );
  }, []);

  const [animationCount, setAnimationCount] = useState(11);
  const [animationFun, setAnimationFun] = useState('a');
  const [animationV_b, setAnimationV_b] = useState(0);
  const [animationValue, setAnimationValue] = useState(0);
  const [animationNum, setAnimationNum] = useState(0);

  useEffect(() => {
    props.changeItem(props.id, cards);
  }, [cards]);

  const animate = async () => {
    // const num = 50 * animationValue;
    const num = animationNum;
    if (animationCount <= num) {
      if (animationFun == 'X') {
        const result = gateX(animationV_b, animationValue, animationCount, num)[0];
        changeV_ab(props.id, result[0], result[1]);
      } else if (animationFun == 'Z') {
        const result = gateZ(animationV_b, animationValue, animationCount, num)[0];
        changeV_ab(props.id, result[0], result[1]);
      } else if (animationFun == 'Y') {
        const result = gateY(animationV_b, animationValue, animationCount, num)[0];
        changeV_ab(props.id, result[0], result[1]);
      } else if (animationFun == 'H') {
        const result = gateH(animationV_b, animationCount, num)[0];
        changeV_ab(props.id, result[0], result[1]);
      }
      setAnimationCount((prevCount) => prevCount + 1);
    }
  };

  useEffect(() => {
    const intervalId = setInterval(animate, 0); //0.3);
    return () => {
      clearInterval(intervalId);
    };
  }, [animationCount]);

  const toFix = (num) => Math.floor(num * 1000) / 1000;
  const { changeV_a, changeV_b, changeV_ab } = props;

  const [{ isOver, canDrop }, drop] = useDrop(
    () => ({
      accept: 'gate',
      drop: (item) => {
        if (item.gate === 'Measure') {
          const percentage = Math.cos((props.qubitValue.v_a / 2) * Math.PI) ** 2;
          console.log(Math.random());
          if (Math.random() <= percentage) {
            setMeasure(0);
            props.changeV_ab(props.id, 0, 0);
          } else {
            setMeasure(1);
            props.changeV_ab(props.id, 1, 0);
          }
        } else {
          const x = Math.sin(props.qubitValue.v_a * Math.PI) * Math.sin(props.qubitValue.v_b * Math.PI);
          const y = Math.cos(props.qubitValue.v_a * Math.PI);
          const z = Math.sin(props.qubitValue.v_a * Math.PI) * Math.cos(props.qubitValue.v_b * Math.PI);
          setAnimationCount(0);
          setAnimationFun(item.gate);
          setAnimationV_b([x, y, z]);
          setAnimationValue(item.value);
          setAnimationNum(item.num);
          animate();
        }
        // const new_item = props.qubitValue.item;
        // new_item.push(item.name);
        // props.changeItem(props.id, new_item);
        // console.log(new_item);
        setCards((list) => [
          ...list,
          { name: item.name, text: item.text, gate: item.gate, value: item.value, num: item.num },
        ]);
        // setId((e) => e + 1);
      },
      collect: (monitor) => ({
        isOver: !!monitor.isOver(),
        canDrop: !!monitor.canDrop(),
      }),
    }),
    [props.qubitValue.v_a, props.qubitValue.v_b]
  );
  const isActive = isOver && canDrop;

  function deleteCard(index) {
    // console.log(cards);
    setCards((prevCards) => prevCards.filter((_, i) => i !== index));
    // const updatedCards = cards.filter((_, i) => i !== index);
    // console.log(updatedCards);
  }

  return (
    <div>
      <animated.div className={styles.boxBloch}>
        <BlochSphere v_a={props.qubitValue.v_a} v_b={props.qubitValue.v_b} />
      </animated.div>
      <p className={styles.quantumSetting}>
        <div>
          <InlineMath>\theta :</InlineMath>
          <input
            type="number"
            value={props.qubitValue.v_a}
            onChange={(e) => changeV_a(props.id, e.target.value)}
            min={0}
            max={2}
            step={0.01}
          ></input>
          <InlineMath> \pi \quad \phi:</InlineMath>
          <input
            type="number"
            value={props.qubitValue.v_b}
            onChange={(e) => changeV_b(props.id, e.target.value)}
            min={0}
            max={2}
            step={0.01}
          ></input>
          <InlineMath>\pi</InlineMath>
        </div>
        <BlockMath>{`\\ket{0}: ${toFix(
          Math.cos((props.qubitValue.v_a / 2) * Math.PI) ** 2 * 100
        )}\\%\\quad  \\ket{1}: ${toFix(
          100 - toFix(Math.cos((props.qubitValue.v_a / 2) * Math.PI) ** 2 * 100)
        )}\\%`}</BlockMath>
      </p>

      {/* <div
        ref={drop}
        style={{
          backgroundColor: isActive ? '#e4ebd3' : '#e0d3eb',
        }}
        className={styles.dropBox}
      >
        {isActive ? 'Drop here!' : 'Drag an item here'}
      </div> */}
      <div className={styles.playBox}>
        <div>Measure: {measure}</div>
        <div className={styles.playBtn} onClick={play}>
          <FaPlay size="8px" />
        </div>
      </div>
      <div ref={drop} className={styles.cardBox}>
        <div style={{ width: '3px', height: '1px' }}></div>
        <div className={styles.resetCardBox}>
          <InlineMath style={{ float: 'left' }}>{'reset'}</InlineMath>
          <div style={{ fontSize: '0.6rem', float: 'left' }}>
            <InlineMath>\theta: 0, \,\; \phi: 0</InlineMath>
          </div>
        </div>
        {cards.map((card, i) => renderCard(card, i, runningCard))}
        {cards.length === 0 && (
          <>
            <div style={{ width: '3px', height: '1px' }}></div>
            <div
              className={styles.resetCardBox}
              style={{
                backgroundColor: isActive ? '#e4ebd3' : '#e0d3eb',
              }}
            >
              {isActive ? 'Drop here!' : 'Drag a gate here.'}
            </div>
          </>
        )}
      </div>
    </div>
  );
}
