import styles from './add.module.css';
import { useAtom } from 'jotai';
import { qubitAtom } from '../screen';
import { useState } from 'react';

function Add() {
  const [qubit, setQubit] = useAtom(qubitAtom);
  const [idOfQubit, setIdOfQubit] = useState(1);

  const onClicked = () => {
    if (qubit.size < 2) {
      setQubit((q) => {
        return new Map(q).set(String(idOfQubit), {
          v_a: Math.random() / 2,
          v_b: Math.random(),
          zIndex: qubit.size,
          item: [],
        });
      });
      setIdOfQubit((idOfQubit) => idOfQubit + 1);
    } else {
      alert('현재 최대 큐비트 수는 2개 입니다.');
    }
  };
  return (
    <button onClick={onClicked} className={styles.add}>
      +
    </button>
  );
}

export default Add;
