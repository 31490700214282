import styles from './sideBar.module.css';
import { useState } from 'react';
import { useSpring, animated } from '@react-spring/web';
import { useDrag } from 'react-dnd';
import { InlineMath, BlockMath } from 'react-katex';
// import { useState, useEffect } from 'react';
import { create, all } from 'mathjs';
import { Scrollbars } from 'react-custom-scrollbars';
import 'katex/dist/katex.min.css';
import { AiOutlineDoubleLeft } from 'react-icons/ai';

function CustomGate(props) {
  return (
    <>
      <div className={styles.thetaInput} style={{ color: '#e0d3eb', fontSize: '1.2rem', marginTop: '10px' }}>
        <InlineMath>\theta = </InlineMath>
        <input
          type="number"
          value={props.customInput}
          onChange={(e) => props.changeCustomInput(e.target.value)}
          min={-2}
          max={2}
          step={0.1}
        ></input>
        <InlineMath>\pi</InlineMath>
      </div>
      <GateBox
        key={props.reset}
        text={`X^{${props.customInput}\\pi}`}
        tex=""
        item={{
          gate: 'X',
          value: props.customInput,
          num: 12,
          name: `X^{${props.customInput}\\pi}`,
          text: `\\begin{bmatrix} 0 & 1 \\\\ 1 & 0 \\end{bmatrix}`,
        }}
      />
      <GateBox
        key={props.reset + 100}
        text={`Y^{${props.customInput}\\pi}`}
        tex={``}
        item={{
          gate: 'Y',
          value: props.customInput,
          num: 12,
          name: `Y^{${props.customInput}\\pi}`,
          text: `\\begin{bmatrix} 0 & -i \\\\ i & 0 \\end{bmatrix}`,
        }}
      />
      <GateBox
        key={props.reset + 200}
        text={`Z^{${props.customInput}\\pi}`}
        tex=""
        item={{
          gate: 'Z',
          value: props.customInput,
          num: 12,
          name: `Z^{${props.customInput}\\pi}`,
          text: `\\begin{bmatrix} 1 & 0 \\\\ 0 & -1 \\end{bmatrix}`,
        }}
      />
    </>
  );
}

function HalfGate() {
  return (
    <>
      <GateBox
        text="I"
        tex={`\\begin{bmatrix} 1 & 0 \\\\ 0 & 1 \\end{bmatrix}`}
        item={{ gate: 'I', value: 1, num: 50, name: 'I', text: `\\begin{bmatrix} 1 & 0 \\\\ 0 & 1 \\end{bmatrix}` }}
      />
      <GateBox
        text="X"
        tex={`\\begin{bmatrix} 0 & 1 \\\\ 1 & 0 \\end{bmatrix}`}
        item={{ gate: 'X', value: 1, num: 50, name: 'X', text: `\\begin{bmatrix} 0 & 1 \\\\ 1 & 0 \\end{bmatrix}` }}
      />
      <GateBox
        text="Y"
        tex={`\\begin{bmatrix} 0 & -i \\\\ i & 0 \\end{bmatrix}`}
        item={{ gate: 'Y', value: 1, num: 50, name: 'Y', text: `\\begin{bmatrix} 0 & -i \\\\ i & 0 \\end{bmatrix}` }}
      />
      <GateBox
        text="Z"
        tex={`\\begin{bmatrix} 1 & 0 \\\\ 0 & -1 \\end{bmatrix}`}
        item={{ gate: 'Z', value: 1, num: 50, name: 'Z', text: `\\begin{bmatrix} 1 & 0 \\\\ 0 & -1 \\end{bmatrix}` }}
      />
      <GateBox
        text="H"
        tex={`{1\\over\\sqrt{2}}\\begin{bmatrix} 1 & 1 \\\\ 1 & -1 \\end{bmatrix}`}
        item={{
          gate: 'H',
          value: 1,
          num: 50,
          name: 'H',
          text: `{1\\over\\sqrt{2}}\\begin{bmatrix} 1 & 1 \\\\ 1 & -1 \\end{bmatrix}`,
        }}
      />
    </>
  );
}
function QuarterGate() {
  return (
    <>
      <GateBox
        text="X^{1\over2}"
        tex={`{1\\over\\sqrt{2}}\\begin{bmatrix} 1 & -i \\\\ -i & 1 \\end{bmatrix}`}
        item={{
          gate: 'X',
          value: 0.5,
          num: 25,
          name: 'X^{1\\over2}',
          text: `{1\\over\\sqrt{2}}\\begin{bmatrix} 1 & -i \\\\ -i & 1 \\end{bmatrix}`,
        }}
      />
      <GateBox
        text="Y^{1\over2}"
        tex={`{1\\over\\sqrt{2}}\\begin{bmatrix} 1 & -1 \\\\ 1 & 1 \\end{bmatrix}`}
        item={{
          gate: 'Y',
          value: 0.5,
          num: 25,
          name: 'Y^{1\\over2}',
          text: `{1\\over\\sqrt{2}}\\begin{bmatrix} 1 & -1 \\\\ 1 & 1 \\end{bmatrix}`,
        }}
      />
      <GateBox
        text="Z^{1\over2}"
        tex={`\\begin{bmatrix} 1 & 0 \\\\ 0 & i \\end{bmatrix}`}
        item={{
          gate: 'Z',
          value: 0.5,
          num: 25,
          name: 'Z^{1\\over2}',
          text: `\\begin{bmatrix} 1 & 0 \\\\ 0 & i \\end{bmatrix}`,
        }}
      />
    </>
  );
}

function EighthGate() {
  return (
    <>
      <GateBox
        text="X^{1\over4}"
        tex={`\\begin{bmatrix} \\cos({\\pi\\over8}) & -i\\sin({\\pi\\over8}) \\\\ -i\\sin({\\pi\\over8}) & \\cos({\\pi\\over8}) \\end{bmatrix}`}
        item={{
          gate: 'X',
          value: 0.25,
          num: 12,
          name: 'X^{1\\over4}',
          text: `\\begin{bmatrix} \\cos({\\pi\\over8}) & -i\\sin({\\pi\\over8}) \\\\ -i\\sin({\\pi\\over8}) & \\cos({\\pi\\over8}) \\end{bmatrix}`,
        }}
      />
      <GateBox
        text="Y^{1\over4}"
        tex={`\\begin{bmatrix} \\cos({\\pi \\over 8}) & -\\sin({\\pi \\over 8}) \\\\ \\sin({\\pi \\over 8}) & \\cos({\\pi \\over 8}) \\end{bmatrix}`}
        item={{
          gate: 'Y',
          value: 0.25,
          num: 12,
          name: 'Y^{1\\over4}',
          text: `\\begin{bmatrix} \\cos({\\pi \\over 8}) & -\\sin({\\pi \\over 8}) \\\\ \\sin({\\pi \\over 8}) & \\cos({\\pi \\over 8}) \\end{bmatrix}`,
        }}
      />
      <GateBox
        text="Z^{1\over4}"
        tex={`\\begin{bmatrix} 1 & 0 \\\\ 0 & e^{i{\\pi\\over 4}} \\end{bmatrix}`}
        item={{
          gate: 'Z',
          value: 0.25,
          num: 12,
          name: 'Z^{1\\over4}',
          text: `\\begin{bmatrix} 1 & 0 \\\\ 0 & e^{i{\\pi\\over 4}} \\end{bmatrix}`,
        }}
      />
    </>
  );
}

function Measure() {
  return (
    <>
      <GateBox
        text="Measure"
        tex={``}
        item={{
          gate: 'Measure',
          value: 0,
          num: 0,
          name: 'Measure',
          text: ``,
        }}
      />
    </>
  );
}

function GateBox(props) {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'gate',
    item: props.item,
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

  return (
    <div ref={drag} className={styles.gateBox}>
      <InlineMath renderSuccess={(html) => <span style={{ margin: 0 }} dangerouslySetInnerHTML={{ __html: html }} />}>
        {props.text}
      </InlineMath>
      <div style={{ position: 'relative', top: '-10px' }}>
        <BlockMath renderSuccess={(html) => <span style={{ margin: 0 }} dangerouslySetInnerHTML={{ __html: html }} />}>
          {props.tex}
        </BlockMath>
      </div>
    </div>
  );
}

function SideBar() {
  const [customInput, setCusotmInput] = useState(0.5);
  const [reset, setReset] = useState(0);
  const [state, setState] = useState(0);

  function SelectBox({ text, id }) {
    return (
      <div
        className={styles.selectBox}
        style={{
          backgroundColor: state === id ? 'rgba(88, 53, 98, 0.451)' : 'transparent',
          borderTop: id === 0 ? 'solid 2px rgba(88, 53, 98, 0.751)' : '',
        }}
        onClick={() => setState(id)}
      >
        {text}
      </div>
    );
  }

  function changeCustomInput(new_value) {
    const toFix = (num) => Math.round(num * 1000) / 1000;
    new_value = toFix(parseFloat(new_value));
    if (new_value > 2) setCusotmInput(2);
    else if (new_value < -2) setCusotmInput(-2);
    else if (isNaN(new_value)) setCusotmInput(0);
    else setCusotmInput(new_value);
    if (reset >= 100) setReset(0);
    else setReset((e) => e + 1);
  }

  const math = create(all);

  const [sideBarOpen, setSideBarOpen] = useState(false);
  const spring = useSpring({
    transform: `translateX(${sideBarOpen ? '-210px' : '70px'})`,
    config: {
      duration: 440,
    },
  });

  return (
    <div className={styles.sideBar}>
      <div className={styles.sidebar1}>
        <div style={{ marginTop: '20px' }}>
          <SelectBox text="Half" id={0} />
          <SelectBox text="Quarter" id={1} />
          <SelectBox text="Eighth" id={2} />
          <SelectBox text="Custom" id={3} />
          <SelectBox text="Measure" id={4} />
        </div>
        {/* <button onClick={() => setSideBarOpen(!sideBarOpen)}>얍!</button> */}
        <div
          style={{
            marginBottom: '20px',
            transform: sideBarOpen ? 'rotate(180deg)' : 'rotate(0deg)',
            transition: 'transform 0.3s',
          }}
          onClick={() => setSideBarOpen(!sideBarOpen)}
        >
          <AiOutlineDoubleLeft size={30} />
        </div>
      </div>
      <animated.div style={spring} className={styles.sidebar2}>
        {/* <div ref={drag} className={`${styles.gateBox} ${styles.dragging}`}></div> */}
        <div className={styles.explorerBox}>
          <BlockMath>
            {state === 0
              ? 'Half: \\pi'
              : state === 1
              ? 'Quarter: {1\\over2}\\pi'
              : state === 2
              ? 'Eighth: {1\\over4}\\pi'
              : state === 3
              ? 'Custom'
              : 'Measure'}
          </BlockMath>
        </div>
        <div className={styles.scrollBox}>
          <Scrollbars
            className={styles.scrollBar}
            renderTrackVertical={({ style }) => (
              <div
                className="track-vertical"
                style={{ ...style, backgroundColor: 'rgba(88, 53, 98, 0.751)', height: '100%' }}
              />
            )}
            renderThumbVertical={({ style }) => (
              <div
                className="thumb-vertical"
                style={{ ...style, backgroundColor: 'rgba(224, 175, 237, 0.751)', borderRadius: '3px' }}
              />
            )}
          >
            {state === 0 ? (
              <HalfGate />
            ) : state === 1 ? (
              <QuarterGate />
            ) : state === 2 ? (
              <EighthGate />
            ) : state === 3 ? (
              <CustomGate customInput={customInput} changeCustomInput={changeCustomInput} reset={reset} />
            ) : (
              <Measure />
            )}
          </Scrollbars>
        </div>
      </animated.div>
    </div>
  );
}

export default SideBar;
