import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Web from './Web/web';

function App() {
  return (
    <div className="App">
      {/* <header className="App-header">
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a className="App-link" href="https://reactjs.org" target="_blank" rel="noopener noreferrer">
          Learn React
        </a>
      </header> */}
      <BrowserRouter>
        <Routes>
          <Route path="/web" element={<Web />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
