import Add from './add/add';
import styles from './screen.module.css';
import Draggable from 'react-draggable';
import { atom, useAtom } from 'jotai';
import QubitBox from './qubitBox/qubitBox';
import { useEffect, useState } from 'react';

export const qubitAtom = atom(
  new Map().set('0', {
    v_a: 0.3,
    v_b: 0.2,
    zIndex: 0,
    item: [],
  })
);

export default function Screen() {
  const [reset, setReset] = useState(false);
  const [qubit, setQubit] = useAtom(qubitAtom);
  const deleteBtnOnClicked = (id) => {
    if (qubit.size > 1) {
      setQubit((q) => {
        const newQ = new Map(q);
        newQ.delete(id);
        return newQ;
      });
    } else {
      alert('최소 1개의 큐비트가 필요합니다.');
    }
  };
  const changeItem = (id, item) => {
    setQubit(() => {
      const newQubit = new Map(qubit);
      newQubit.set(id, { ...newQubit.get(id), item });
      return newQubit;
    });
  };
  const changeV_a = (id, v_a) => {
    const toFix = (num) => Math.round(num * 100) / 100;
    setQubit(() => {
      const newQubit = new Map(qubit);
      v_a = toFix(parseFloat(v_a));
      if (v_a >= 2) newQubit.set(id, { ...newQubit.get(id), v_a: toFix(v_a - Math.floor(v_a / 2) * 2) });
      else if (v_a <= 0) newQubit.set(id, { ...newQubit.get(id), v_a: toFix(v_a + 2 - Math.floor(-v_a / 2) * 2) });
      else if (isNaN(v_a)) newQubit.set(id, { ...newQubit.get(id), v_a: 0 });
      else newQubit.set(id, { ...newQubit.get(id), v_a });
      return newQubit;
    });
  };
  const changeV_b = (id, v_b) => {
    const toFix = (num) => Math.round(num * 100) / 100;
    setQubit(() => {
      const newQubit = new Map(qubit);
      v_b = toFix(parseFloat(v_b));
      if (v_b >= 2) newQubit.set(id, { ...newQubit.get(id), v_b: toFix(v_b - Math.floor(v_b / 2) * 2) });
      else if (v_b <= 0) newQubit.set(id, { ...newQubit.get(id), v_b: toFix(v_b + 2 - Math.floor(-v_b / 2) * 2) });
      else if (isNaN(v_b)) newQubit.set(id, { ...newQubit.get(id), v_b: 0 });
      else newQubit.set(id, { ...newQubit.get(id), v_b });
      // newQubit.set(id, { ...newQubit.get(id), v_b });
      return newQubit;
    });
  };
  const changeV_ab = (id, v_a, v_b) => {
    const toFix = (num) => Math.round(num * 100) / 100;
    setQubit(() => {
      const newQubit = new Map(qubit);
      v_a = toFix(parseFloat(v_a));
      v_b = toFix(parseFloat(v_b));
      if (v_a >= 2) newQubit.set(id, { ...newQubit.get(id), v_a: toFix(v_a - Math.floor(v_a / 2) * 2) });
      else if (v_a <= 0) newQubit.set(id, { ...newQubit.get(id), v_a: toFix(v_a + 2 - Math.floor(-v_a / 2) * 2) });
      else if (isNaN(v_a)) newQubit.set(id, { ...newQubit.get(id), v_a: 0 });
      else newQubit.set(id, { ...newQubit.get(id), v_a });
      if (v_b >= 2) newQubit.set(id, { ...newQubit.get(id), v_b: toFix(v_b - Math.floor(v_b / 2) * 2) });
      else if (v_b <= 0) newQubit.set(id, { ...newQubit.get(id), v_b: toFix(v_b + 2 - Math.floor(-v_b / 2) * 2) });
      else if (isNaN(v_b)) newQubit.set(id, { ...newQubit.get(id), v_b: 0 });
      else newQubit.set(id, { ...newQubit.get(id), v_b });
      // newQubit.set(id, { ...newQubit.get(id), v_b });
      return newQubit;
    });
  };
  const changeZIndex = (id) => {
    setQubit(() => {
      const newQubit = new Map(qubit);
      const zIndex = newQubit.get(id).zIndex;
      newQubit.forEach((value, key) => {
        if (value.zIndex > zIndex) {
          newQubit.set(key, { ...value, zIndex: value.zIndex - 1 });
        }
      });
      newQubit.set(id, { ...newQubit.get(id), zIndex: newQubit.size - 1 });
      return newQubit;
    });
  };
  useEffect(() => {
    setReset((e) => !e);
  }, [qubit]);
  return (
    <div className={styles.screen}>
      <Add />
      {Array.from(qubit.entries()).map(([key, qubitValue]) => {
        return (
          <Draggable key={key} bounds="parent" handle="strong" onMouseDown={() => changeZIndex(key)}>
            <div className={styles.box} style={{ zIndex: qubitValue.zIndex }}>
              <strong className="cursor">
                <div className={styles.boxHead}>
                  <span>qubit: {key}</span>
                  <button onClick={() => deleteBtnOnClicked(key)} className={styles.deleteDot}></button>
                </div>
              </strong>
              <QubitBox
                qubitValue={qubitValue}
                id={key}
                changeV_a={(id, v_a) => changeV_a(id, v_a)}
                changeV_b={(id, v_b) => changeV_b(id, v_b)}
                changeV_ab={(id, v_a, v_b) => changeV_ab(id, v_a, v_b)}
                changeItem={(id, item) => changeItem(id, item)}
                reset={reset}
              />
            </div>
          </Draggable>
        );
      })}
    </div>
  );
}
