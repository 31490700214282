import { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import styles from './card.module.css';
import { InlineMath } from 'react-katex';

const style = {
  //   border: '1px dashed gray',
  //   padding: '0.5rem 1rem',
  //   marginBottom: '.5rem',
  //   backgroundColor: 'white',
  cursor: 'move',
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
};
const ItemTypes = {
  CARD: 'card',
};

export const Card = ({ name, index, moveCard, text, runningCard, deleteCard }) => {
  const ref = useRef(null);
  const [{ handlerId }, drop] = useDrop({
    accept: ItemTypes.CARD,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) {
        return;
      }
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      moveCard(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });
  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.CARD,
    item: () => {
      return { index };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  drag(drop(ref));
  return (
    <>
      <div style={{ width: '3px', height: '1px' }}></div>
      <div
        ref={ref}
        className={styles.cardBox}
        style={{ ...style, opacity: 1, backgroundColor: runningCard === index ? '#a2dbce' : '#e0d3eb' }}
        data-handler-id={handlerId}
      >
        <div style={{ display: 'flex', gap: '10px' }}>
          <InlineMath style={{ float: 'left' }}>{name}</InlineMath>
          <div style={{ fontSize: '0.6rem', float: 'left' }}>
            <InlineMath>{text}</InlineMath>
          </div>
        </div>
        <div>
          <div className={styles.deleteBtn} onClick={() => deleteCard(index)}></div>
        </div>
      </div>
    </>
  );
};
