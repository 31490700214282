import styles from './web.module.css';
import SideBar from './sideBar/sideBar';
import Screen from './screen/screen';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';

function Web() {
  // const isTouchDevice = 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;

  return (
    <div className={styles.web}>
      {/* <DndProvider backend={isTouchDevice ? TouchBackend : HTML5Backend}> */}
      <DndProvider backend={HTML5Backend}>
        <SideBar />
        <Screen />
      </DndProvider>
    </div>
  );
}

export default Web;
