// import { Canvas } from '@react-three/fiber';
import * as THREE from 'three';
import { Text, OrbitControls } from '@react-three/drei';
import { Canvas } from 'react-three-fiber';

const stateColor = 0xbe01e5;

const Sphere = function Sphere() {
  return (
    <mesh visible position={[0, 0, 0]} rotation={[0, 0, 0]}>
      <sphereGeometry args={[1, 20, 8]} />
      <meshBasicMaterial attach="material" color="yellow" opacity={0.2} transparent />
      <axesHelper args={[1]} />
    </mesh>
  );
};

const StateSphere = function StateSphere(props) {
  const [a, b] = props.rotation;
  const x = Math.sin(a) * Math.sin(b);
  const y = Math.cos(a);
  const z = Math.sin(a) * Math.cos(b);
  return (
    <mesh visible position={[x, y, z]} rotation={[0, 0, 0]}>
      <sphereGeometry args={[0.05, 5, 3]} />
      <meshBasicMaterial attach="material" color={stateColor} />
    </mesh>
  );
};

const State = function State(props) {
  const [a, b] = props.rotation;
  const x = Math.sin(a) * Math.sin(b);
  const y = Math.cos(a);
  const z = Math.sin(a) * Math.cos(b);
  return (
    <mesh visible position={[0, 0, 0]} rotation={[0, 0, 0]}>
      <arrowHelper args={[new THREE.Vector3(x, y, z), new THREE.Vector3(0, 0, 0), 1, stateColor, 0, 0]} />
    </mesh>
  );
};

const QubitBlochSphere = function QubitBlochSphere(props) {
  return (
    <mesh visible position={props.position} rotation={props.rotation}>
      <torusGeometry args={[1, 0.005, 3, 30]} />
      <meshBasicMaterial attach="material" color="hotpink" />
    </mesh>
  );
};

const ExplainTextFunc = function ExplainTextFunc(props) {
  return (
    <mesh position={props.position} rotation={props.rotation}>
      <Text depthTest={false} material-toneMapped={false} layers={undefined} fontSize={0.15}>
        {props.text}
      </Text>
    </mesh>
  );
};

const ExplainText = function ExplainText() {
  return (
    <>
      <ExplainTextFunc position={[0, 1.1, 0]} rotation={[0, 0, 0]} text="|0>" />
      <ExplainTextFunc position={[0, -1.1, 0]} rotation={[0, 0, 0]} text="|1>" />
      <ExplainTextFunc position={[1.15, 0, 0]} rotation={[0, 0, 0]} text="|+i>" />
      <ExplainTextFunc position={[-1.15, 0, 0]} rotation={[0, 0, 0]} text="|-i>" />
      <ExplainTextFunc position={[0, 0, 1.15]} rotation={[0, -Math.PI / 2, 0]} text="|+>" />
      <ExplainTextFunc position={[0, 0, -1.15]} rotation={[0, -Math.PI / 2, 0]} text="|->" />
    </>
  );
};

const Qubit = function Qubit(props) {
  // rotationA: 번데기, rotationB: 중, 각도를 활용한 블로흐 구 표현
  // const [rotationA, setRotationA] = useState(parseFloat(props.v_a));
  // const [rotationB, setRotationB] = useState(parseFloat(props.v_b));
  const rotationA = parseFloat(props.v_a) * 2 * Math.PI;
  const rotationB = parseFloat(props.v_b) * 2 * Math.PI;
  //---------------------------------------------------------------------------------

  // [c]
  // [d] 형태: 상태를 활용한 블로흐 구 표현
  // const c = (1 / Math.sqrt(2)) * 0 + 1.2 / Math.sqrt(2);
  // const d = (1 / Math.sqrt(2)) * 0;
  // const di = (1 / Math.sqrt(2)) * 0;
  // const a = Math.acos(c) * 2;
  // let b;
  // if (a === 0) {
  //   b = 0;
  // } else {
  //   if (di === 0) {
  //     b = Math.acos(d / Math.sin(a / 2));
  //   } else {
  //     b = Math.asin(di / Math.sin(a / 2));
  //   }
  // }
  // const [rotationA, setRotationA] = useState(a);
  // const [rotationB, setRotationB] = useState(b);
  //---------------------------------------------------------------

  // useEffect(() => {
  //   setRotationA((preveNum) => preveNum + 0.001);
  //   setRotationB((preveNum) => preveNum + 0.0005);
  // });

  return (
    <>
      <StateSphere rotation={[rotationA, rotationB]} />
      <OrbitControls enableZoom={false} enablePan={false} />
      <QubitBlochSphere position={[0, 0, 0]} rotation={[0, 0, 0]} />
      <QubitBlochSphere position={[0, 0, 0]} rotation={[Math.PI / 2, 0, 0]} />
      <QubitBlochSphere position={[0, 0, 0]} rotation={[0, Math.PI / 2, 0]} />
      <Sphere />
      <ExplainText />
      <State rotation={[rotationA, rotationB]} />
    </>
  );
};

function BlochSphere(props) {
  return (
    // <div className={styles.blochSphere}>
    // <div className={styles.canvas}>
    <Canvas style={{ width: '100%', height: '100%' }} camera={{ position: [1, 0.5, 1.658] }}>
      {/* <Canvas concurrent pixelRatio={1} orthographic camera={{ position: [0, 0, 2] }}> */}
      <Qubit v_a={props.v_a / 2} v_b={props.v_b / 2} v_c={props.v_c} />
    </Canvas>
    // </div>
    // </div>
  );
}

export default BlochSphere;
